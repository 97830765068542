@import "variables";
.MuiChip-colorPrimary {
    color: #fff;
}
.link-tag {
    text-decoration: none;
    color: $darkgray-color;
    &.inactive-tag {
        background-color: #c1c1c1 !important;
        cursor: default !important;
    }
}

.category-ul-list {
    padding: 0;
    list-style: none;
    margin: 0;
    li {
        &.custon-list {
            font-size: 16px;
            width: 100%;
            // border: 1px solid #c4c4c4;
            display: block;
            // padding: 16px 12px;
            border-radius: 4px;
            position: relative;
            text-transform: capitalize;
            margin-bottom: 20px;
            display: flex;
            justify-content: center;
            align-items: center;
            span.menu-icon {
                position: absolute;
                right: 16px;
                top: 15px;
            }
            .MuiChip-root {
                background-color: $yellow-color;
                .MuiChip-label {
                    color: $darkgray-color;
                }
                .MuiChip-deleteIcon {
                    color: rgba(255, 255, 255, 0.7);
                    &:hover {
                        color: #fff;
                    }
                }
            }
        }
        .category-img-sec {
            height: 60px;
            width: 60px;
            // border: 1px solid black;
            border: 0px;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            padding: 5px;
            align-items: center;
            .category-img {
                object-fit: contain;
                width: 100%;
                height: 100%;
                margin-right: 12px;
            }
        }
        .left-section {
            margin-right: 10px;
            width: 300px;
        }
        .category-sec-list-drag {
            padding: 10px;
        }
    }
}

.manage-product-section {
    .search-section {
        padding: 10px 0;
        .MuiOutlinedInput-root {
            background-color: #fff;
        }
    }
}
.flowertype-list {
    img {
        height: 20px;
        width: 20px;
        margin: 0px 0px -5px 5px;
    }
}

.modal-title {
    position: absolute;
    right: 0;
    top: -10px;
}

.cus-modal {
    button.close {
        background-color: transparent;
        right: -10px;
        padding: 0;
    }

    h4 {
        &.jc-ctr {
            font-size: 26px;
            text-transform: uppercase;
            font-weight: 600;
            margin: 0px 0 0 15px;
        }
    }
    .add-category-input {
        padding: 20px 0;
        .browse-list {
            margin-bottom: 20px;
            border: 1px dotted rgba(0, 0, 0, 0.23);
            border-radius: 4px;
            padding: 15px;
            text-align: center;
            &:focus {
                outline: none;
            }
            .drop-files {
                &:focus {
                    outline: none;
                }
            }
        }
        .category-images-container {
            .category-image-item-1 {
                border-right: 1px solid rgb(211, 211, 211);
                padding-right: 5px;
            }
            .category-image-item-2 {
                padding-left: 5px;
            }
            // div:nth-child(1) {
            // }
            // div:nth-child(2) {
            // }
            .inner-select-image-item {
                position: relative;
            }
            .select-category-image {
                padding: 10px 5px;
                border: 1px solid #c7c7c7;
                border-radius: 4px;
                margin: 5px;
                height: 60px;
                cursor: pointer;
                display: flex;
                img {
                    margin: auto;
                    max-width: 100%;
                    max-height: 100%;
                }
                &.selected {
                    width: 80px;
                    height: 80px;
                    border-color: #46bd90 !important;
                    border-width: 2px;
                    height: 140px;
                }
                &.disable-select {
                    // background: #e6e6e6;
                    border-color: #46bd90;
                    border-width: 2px;
                    cursor: default;
                    img {
                        max-width: 102%;
                        max-height: 102%;
                    }
                }
            }
            .tick-placement {
                position: absolute;
                // display: flex;
                right: 0;
                svg {
                    // margin: auto;
                    color: #46bd90;
                }
            }
        }
    }

    .cus-label {
        margin-bottom: 0;
    }
    .add-category-input {
        .category-image-item-2 {
            min-height: 250px;
            display: flex;
            align-items: center;
            justify-content: center;
            .select-category-image {
                width: 100px;
            }
        }
    }
    .category-images-container {
        .scrollable-sec {
            max-height: 250px;
            overflow: auto;
        }
    }
}

.add-product-input {
    padding: 20px 0;
}

.add-category-btn {
    display: flex;
    justify-content: flex-end;
}

.MuiList-root {
    &.MuiList-padding {
        padding: 0px !important;
    }
}

.category-indexing {
    display: flex;
    align-items: center;
    padding-right: 10px;
}

.del-category,
.edit-category {
    display: flex;
    align-items: center;
    padding-left: 10px;
    cursor: pointer;
}

.single-prduct-detail-container {
    .left-sec {
        padding-right: 15px;
        > .MuiGrid-root {
            box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
            margin: 0 0px 20px 0;
        }
        .product-details-listing {
            padding: 30px 30px 0;
            h4 {
                font-weight: 600;
                font-size: 26px;
            }
            p {
                padding: 0;
            }
            .effects-sec {
                .effects-listing {
                    display: flex;
                    flex-direction: row;
                    padding: 0px;
                    .effect-chkbox {
                        width: 33.33%;
                        text-transform: capitalize;
                        label {
                            margin: 0 0 15px 0;
                            .MuiCheckbox-root {
                                padding: 0 5px 0 0;
                            }
                        }
                    }
                }
            }
        }
        .product-save-btn {
            display: flex;
            padding: 20px;
        }
    }

    .right-sec {
    }
}

.sidebar-left-top-sec {
    .sidebar-left-top-logo {
        margin-top: 0px;
        height: 42px;
        width: 100%;
        img {
            padding: 0;
            max-height: 100%;
            max-height: 100%;
        }
    }
    button {
        padding: 0px;
        margin-right: 10px;
    }
}

.appointments-page-container {
    .zipcode-sec {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        padding: 40px 40px 20px;
        margin-bottom: 20px;
    }
    .add-appointment-btn {
        display: flex;
        justify-content: flex-end;
        button {
            height: 40px;
            margin-top: 8px;
        }
    }
    .MuiBox-root {
        flex-direction: column;
    }
    .blaze-zipcode-textarea {
        max-width: 100%;
        min-height: 100px;
        width: 100%;
        border-radius: 0px;
        font-size: 15px;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        outline: none;
        // border: none;
        // box-shadow: 0 2px 6px 0 #e0e0e0;
        resize: none;
        margin: 0 auto 15px;
        &:focus {
            border-color: grey;
            outline: none;
            background-color: white;
            box-shadow: none;
        }
        &::placeholder {
            color: rgb(175, 175, 175);
        }
    }
    .save-zipcode-btn {
        height: 40px;
        max-width: 150px;
        border-radius: 0px;
        border: none;
        outline: none;
        margin: 0;
        background: $black-color;
        font-family: "Roboto", "Helvetica", "Arial", sans-serif;
        color: white;
        font-size: 15px;
        cursor: pointer;
        box-shadow: none !important;
        font-weight: 500;
        text-transform: uppercase;
        width: 100%;
        &:hover {
            background-color: $dark-green;
        }
    }
}

.last-sync {
    padding: 15px;
    align-items: center;
    background-color: $yellow-color;
    // border: 2px solid #49be91;
    // box-shadow: 0px 0px 0px -1px rgb(0 0 0 / 4%), 0px 4px 0px 0px rgb(0 0 0 / 0%), 0px 1px 4px 0px rgb(0 0 0 / 24%);
    margin-bottom: 20px !important;
    font-weight: 500;
    color: $darkgray-color;
    // color: #49be91;
    display: flex;
    justify-content: space-between;
    border-radius: 0px;
    .product-sync-div {
        background-color: $darkgray-color;
        color: $white-color;
        align-items: center;
        height: 35px;
        display: flex;
        justify-content: center;
        width: 120px;
        font-size: 14px;
        text-align: center;
        border-radius: 4px;
        cursor: pointer;
        svg {
            color: #46952f;
        }
        &.disabled-text {
            color: #85bba1;
            cursor: default !important;
        }
    }
}

main {
    &.promotions-container {
        height: auto;
    }
}
.promotions-specials-sec {
    border: 0px solid black;
    padding: 0;
    margin-bottom: 25px !important;
    margin-top: 40px !important;

    .specials-top-sec {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: rgba(0, 0, 0, 0.04);
        padding: 12px;
        border-radius: 0px;
        margin-bottom: 35px;
        .info {
            display: flex;
            align-items: center;
            svg {
                width: 18px;
                height: 18px;
                margin-right: 10px;
            }
        }
        button {
            height: 46px;
        }
    }

    .special-products-listing {
        // padding: 10px 0 0 0;
        .delivery-partner {
            .special-sec-list {
                width: 100%;
                display: flex;
                position: relative;
                align-items: center;
                padding: 15px;
                margin-top: 10px;
                background-color: #fff;
                margin-bottom: -1px;
                &:focus {
                    outline: none;
                }
                h6 {
                    display: flex;
                    svg {
                        margin-right: 20px !important;
                        width: 20px;
                        height: 20px;
                        margin-right: 6px;
                    }
                }
                .del-special-product {
                    // display: flex;
                    float: right;
                    align-items: center;
                    margin-left: 10px;
                    position: absolute;
                    right: 12px;
                    svg {
                        width: 20px;
                        height: 20px;
                    }
                }
            }
        }
    }
}

.promotions-container {
    .top-btn-sec {
        display: flex;
        justify-content: flex-end;
        padding: 0px 0 20px;
        button {
            height: 40px;
        }
    }

    .promotions-table {
        .promo-name {
            width: 20%;
        }
    }
}

body {
    .MuiButton-containedPrimary {
        background-color: $darkgray-color;
        height: 45px;
        min-width: 180px;
        border-radius: 0px !important;
        box-shadow: none !important;
        &:hover,
        &:focus {
            background-color: $yellow-color;
            color: $darkgray-color;
        }
    }
    .MuiCheckbox-colorPrimary {
        &.Mui-checked {
            color: #0d98ba;
        }
    }
    .manage-category-page-container {
        padding-bottom: 100px;
        .add-category-btn {
            margin: 0px 0 20px;
            button {
                height: 46px;
            }
        }
        .save-button-div {
            padding-top: 10px;
        }
    }
    .confirmation-modal {
        padding: 30px 0px 10px;
        button {
            height: 46px;
            min-width: 140px;
        }
        h5 {
            font-size: 20px;
        }
    }
}

.cus-public-header {
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    height: auto;
    min-height: 100vh;
    background: #ebebeb;

    .login-custom-width {
        background: #ffffff;

        img.logimg-icon {
            width: 100%;
            object-fit: cover;
        }

        .form-wrapper {
            border: 2px solid #d4d3d3;
            border-right: 0;
        }
    }
}

.appbar-loggedin-email {
    margin: 0px;
    color: rgba(0, 0, 0, 0.87);
    margin-right: 10px;
}
.appbar-logout-btn {
    color: #0d98ba !important;
    cursor: pointer;
    &:hover {
        color: #46bd90 !important;
    }
}

.admin-container {
    .custom-button-div {
        width: 180px;
        float: right !important;
        button {
        }
    }
    .password-table-row {
        position: relative;
        .password-span {
            padding-left: 25px;
        }
        .show-icon {
            position: absolute;
            top: 35%;
            svg {
                cursor: pointer;
                // margin-top: 10px;
                font-size: 18px;
            }
        }
    }
}

.cursor-pointer {
    cursor: pointer;
}

.table-loading {
    display: flex;
    justify-content: center;
}

.add-trucks-container {
    .top-sec {
        display: flex;
        justify-content: space-between;
        padding: 0px 0 20px;
        .right-sec {
            button {
                margin: 0 5px;
            }
        }
    }
    .truck-form-container {
        box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
        padding: 20px;
    }
}
.trucks-form-container {
    .trucks-form {
        display: flex;
        flex-direction: column;
        align-items: end;
        padding: 10px;
        h4 {
            font-size: 20px;
            font-weight: 500;
            margin-bottom: 10px;
            &.zipcode-title {
                display: flex;
                align-items: center;
            }
        }

        .ordering-hours-listing {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 10px;
            .ordering-hours-title {
                width: 40%;
                text-align: left;
            }
        }
    }
}

.trucks-container {
    .top-btn-sec {
        display: flex;
        justify-content: flex-end;
        padding: 0px 0 20px;
        button {
            height: 40px;
        }
    }
}

.map-sec-container {
    width: 100%;
    display: inline-block;
    overflow: hidden;
    height: 300px;
    margin-top: 20px;
    .map-sec {
        width: 100%;
        display: inline-block;
        min-height: 300px;
    }
}

.truck-form-container {
    background-color: white;
    border-radius: 4px;
    padding: 10px;
    .add-zipcode-icon {
        font-size: 24px;
        margin-left: 7px;
        cursor: pointer;
    }
}
.cus-table-dropdown {
    .MuiIconButton-root:hover {
        background-color: transparent;
    }
}
.edit-pop-up {
    ul {
        li {
            background-color: transparent !important;
            cursor: inherit;
        }
    }
}

.cust-google {
    width: 100%;
}

.add-notification-grid-item {
    margin: auto !important;
    box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
    padding: 30px;
    border-radius: 4px;
}

.view-cart-item-btn {
    height: 40px !important;
    min-width: 100px !important;
    font-size: 12px !important;
}
